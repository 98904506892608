@import 'styles/theme.scss';

.twoColumnsContainer {
  width: 66%;
  margin: 80px auto;
  text-align: center;
}

.threeColumnsContainer {
  width: 100%;
  margin: 80px auto;
  text-align: center;
}

.button {
  max-width: 290px;
  width: 100%;
  border-radius: 4px;
  font-weight: $font-weight-500 !important;
  font-size: 18px !important;
  @media (max-width: $md) {
    max-width: 100%;
  }
}

.Contained {
  color: $white;
}

.MainCTA {
  background-color: $main-cta !important;
  color: $white;

  &:hover {
    background-color: $main-cta-dark !important;
  }
}

.Outlined {
  color: $secondary-main;
}

.Large {
  height: 100px;
  background-color: $primary-100 !important;
  border: 1px solid $primary-dark !important;
  color: $primary-dark !important;
  font-weight: $font-weight-700 !important;
  font-size: 22px !important;

  &:hover {
    background-color: $primary-50 !important;
  }
}

.imgWrapper {
  height: 100px;
  position: relative;
  object-fit: cover;
  background-color: $primary-100;
  border-radius: 5px;

  @media (max-width: $md) {
    height: 224px;
    > div {
      max-width: calc(100% - 16px);
    }
    div {
      height: 224px !important;
      max-width: 100%;
    }
  }
}

.img {
  border-radius: 5px;
}
